//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-852:_6616,_4088,_2106,_2232,_6320,_8560,_5688,_9527;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-852')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-852', "_6616,_4088,_2106,_2232,_6320,_8560,_5688,_9527");
        }
      }catch (ex) {
        console.error(ex);
      }